import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { NotFound } from '../../error/NotFound';
import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import { AccessDenied } from '../../error/AccessDenied';
import { AdminHome } from '../../admin';
import { ErrorRoute } from '../../error/routes';
import { SignOutRoute } from '../../signOut/routes';
import { SignOut } from '../../signOut/SignOut';
import { USER_ROUTE } from '../../user/routes/user.routes';
import { CLIENT_ROUTE } from '../../client/routes/client.routes';
import { EMPLOYEE_ROUTE } from '../../employee/routes/employee.routes';
import { DOCUMENT_ROUTE } from '../../document/routes/document.routes';
import { SIGNER_ROUTE } from '../../signer/routes/signer.routes';
import { TestPage } from '../../admin/TestPage';
import { AdminRoute } from '../../admin/routes';
import { PASSPORT_ROUTE } from '../../passport/routes/passport.routes';
import { CERTIFICATE_ROUTE } from '../../certificate/routes/certificate.routes';
import { ACCOUNT_ROUTE } from '../../account/routes/account.routes';
import { SNILS_ROUTE } from '../../snils/routes/snils.routes';
import { VERIFICATION_ROUTE } from '../../verification/routes/verification.routes';

export function AuthorizedRoutes() {
  return (
    <Routes>
      <Route path="admin" element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
        <Route path="" element={<AdminHome />} />
        <Route path={AdminRoute.TEST_PAGE} element={<TestPage />} />
      </Route>
      <Route path={SignOutRoute.SIGN_OUT} element={<SignOut />} />
      <Route path={ErrorRoute.ACCESS_DENIED} element={<AccessDenied />} />
      {USER_ROUTE}
      {CLIENT_ROUTE}
      {EMPLOYEE_ROUTE}
      {DOCUMENT_ROUTE}
      {SIGNER_ROUTE}
      {PASSPORT_ROUTE}
      {CERTIFICATE_ROUTE}
      {ACCOUNT_ROUTE}
      {SNILS_ROUTE}
      {VERIFICATION_ROUTE}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
